<template>
  <header>
    <nav class="topNav">
      <h1>Dominik Vrabič Dežman</h1>
      <DropdownComponent
        :options="{ title: 'Contact', choices: [{ choice: 'Email', link: 'mailto:hello@dominik.ooo' }, { choice: 'Linked.in', link: 'https://www.linkedin.com/in/dominik-vd/' }, { choice: 'Are.na', link: 'https://www.are.na/dominik-vd/' }, { choice: 'Twitter', link: 'https://www.twitter.com/dmnkvd' }, { choice: 'Instagram', link: 'https://www.instagram.com/dominik.ooo' }] }"
      />
    </nav>
    <article class="introduction">
      <div class="text-container">
        <p>Hi! 👋 I'm Dominik.</p>
        <p>
          <span
            class="one"
            :style="{ textDecorationColor: themes[selectedTheme][Math.floor(Math.random() * themes[selectedTheme].length)] }"
          >Digital product designer</span> and <span
            class="two"
            :style="{ textDecorationColor: themes[selectedTheme][Math.floor(Math.random() * themes[selectedTheme].length)] }"
          >media scholar</span> (SI). I'm currently at the University of Amsterdam (NL). Below are some
          of
          my
          recent projects and happenings.
        </p>
        <p>
          I'm happy to discuss projects in UX, Digital Product Design,
          Web
          Development, Print, and research in Media Studies, Philosophy.
        </p>
      </div>

    </article>
    <!-- <p class="introduction">See my Work <br /> <span class="arrow">↓</span></p> -->
    <nav class="menuChoices">
      <section class="navLeft">
        <ShowMenu />
        <ListToggleDropdownComponent />
      </section>
      <ThemeDropdownComponent />
    </nav>
  </header>
</template>
<script>
import DropdownComponent from './DropdownComponent.vue'
import ListToggleDropdownComponent from './ListToggleDropdownComponent.vue';
import ShowMenu from './ShowMenu.vue';
import ThemeDropdownComponent from './ThemeDropdownComponent.vue';
import { mapGetters, mapState } from 'vuex';

export default {
  components: {
    DropdownComponent,
    ShowMenu,
    ThemeDropdownComponent,
    ListToggleDropdownComponent
  },
  data() {
    return {}
  },
  methods: {},
  mounted() {
    const captionsOne = ['Information Designer'];
    // const captionsOneB = ['UX', 'Print', 'Web', 'Interaction', 'Identity', 'Branding'];
    const captionsTwo = ['Media Scholar', 'Media Philosopher', 'Media Researcher'];

    document.querySelector('.one').innerHTML = captionsOne[Math.floor(Math.random() * captionsOne.length)];
    document.querySelector('.two').innerHTML = captionsTwo[Math.floor(Math.random() * captionsTwo.length)]
  },
  computed: {
    ...mapState(['themes', 'selectedTheme']), ...mapGetters(['accentColour']),
  },
}
</script>
<style scoped lang="scss">
header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 500;
  min-height: 94vh;
  // margin-bottom: 1rem;
}

.navLeft {
  display: flex;

  &>*:first-child {
    margin-right: 1rem;
  }
}

.topNav {
  display: flex;
  justify-content: space-between;
}

header,
.introduction {
  padding: 1.2rem 1.2rem 1.45rem 1.2rem;
}

.menuChoices {
  display: flex;
  justify-content: space-between;
}

.introduction {
  font-size: var(--font-l);
  line-height: 1.2;
  padding: 4rem 2rem 6rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--fore);

  .text-container {
    max-width: 50ch;
  }

  p {
    font-size: var(--font-l);
    margin-bottom: 1rem;
    // transition: 0.4s font-size ease;
  }

  p:nth-child(1) {
    font-size: 2rem;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.arrow {
  font-family: var(--font-arrow);
}

.one,
.two {
  transition: 0.2s text-decoration-color ease;
  text-decoration-skip-ink: none;
}

.one {
  text-decoration-line: underline;
  text-decoration-color: red;
  text-decoration-style: wavy;
  text-decoration-thickness: 2px;
  text-underline-offset: 3px;
}

.two {
  text-decoration-line: underline;
  text-decoration-color: red;
  text-decoration-style: dotted;
  text-decoration-thickness: 3px;
  text-underline-offset: 3px;
}

h1 {
  font-weight: 500;
}

@media screen and (max-width: 800px) {

  header {
    min-height: 100vh;
    padding: 1rem;
  }

  // In Child Component
  .displayToggle {
    display: none;
  }

  .introduction {
    padding: 4rem 0.5rem 4rem 0.5rem;

    .text-container {
      max-width: 100%;
    }

    p {
      font-size: var(--font-l);
    }

    p:nth-child(1) {
      font-size: var(--font-xl);
    }
  }
}

@media screen and (min-width: 1200px) {
  .introduction {
    p {
      font-size: 1.7rem;
    }

    p:nth-child(1) {
      font-size: #{1.25 * 1.25 * 1.7rem};
    }
  }
}
</style>
