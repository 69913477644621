<template>
  <fieldset class="select-categories">
    <!-- TODO: refactor so it matches the function... -->
    <p class="show">Filter</p>
    <div class="select-container">
      <div
        v-for="cat in            colorCategories           "
        :key="cat.title"
        class="container"
      >
        <label
          @click="disableColor = true"
          @mouseout="disableColor = false"
          :class="{ disable: disableColor }"
          :for="cat.title"
        >
          <input
            type="checkbox"
            :value="cat.title"
            name="show"
            :id="cat.title"
            v-model="chkCategories"
          />
          <!-- <span class="checkmark"></span> -->
          <p
            :aria-label="cat.title"
            :style="{ '--color': cat.color }"
            class="category"
          >{{ cat.title }}</p>
        </label>
      </div>
    </div>
  </fieldset>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ShowMenu',
  props: {
  },
  data() {
    return {
      hovered: 5,
      disableColor: false,
    }
  },
  computed: {
    ...mapGetters(['colorCategories']),
    chkCategories: {
      get: function () {
        return this.$store.state.checkedCategories;
      },
      set: function (val) {
        this.$store.commit('setNames', val);
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.select-categories {
  display: flex;
  justify-content: baseline;

  .show {
    margin-right: 0.5rem;
  }
}

.select-container {
  position: relative;
  top: -0.1em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.show {
  // width: 2.8em;
}

.container {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 1.2;
}

// Add a comma to every element except the last one
.container:not(:last-child) label:after {
  content: ',';
  display: inline;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  cursor: pointer;
}

/* Checkbox un-checked style */
input[type="checkbox"]+p {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s color ease-in-out;
}

/* Checkbox un-checked style */
input[type="checkbox"]+p:before {
  box-sizing: border-box;
  content: '';
  background-color: var(--grey-t);
  border-radius: 100px;
  display: inline-block;
  width: var(--circle);
  height: var(--circle);
  margin-right: 0.5rem;
  vertical-align: -2px;
  cursor: pointer;
}

/* Checked checkbox style (in this case the background is red) */
input[type="checkbox"]:checked+p:before {
  /* NOTE: Replace the url with a path to an SVG of a checkmark to get a checkmark icon */
  background-repeat: no-repeat;
  background-position: center;
  /* The size of the checkmark icon, you may/may not need this */
  background-size: 25px;
  background-color: var(--color);
  color: var(--fore);
  border: none;
}

input[type="checkbox"]:not(:checked)+p {
  color: var(--grey-text);
}

label {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (hover: hover) {

  // /* Checked checkbox style (in this case the background is red) */
  input[type="checkbox"]:checked:hover+p:before {
    background-color: var(--grey-t);
  }

  input[type="checkbox"]:not(:checked):hover+p:before {
    background-color: var(--color);
  }

  input[type="checkbox"]:checked:hover+p {
    color: var(--grey-text);
  }

  input[type="checkbox"]:not(:checked):hover+p {
    color: inherit;
  }

  .disable {
    input[type="checkbox"]:checked:hover+p:before {
      background-color: var(--color);
    }

    input[type="checkbox"]:not(:checked):hover+p:before {
      background-color: var(--grey-t);
    }

    input[type="checkbox"]:checked:hover+p {
      color: inherit;
    }

    input[type="checkbox"]:not(:checked):hover+p {
      color: var(--grey-text);
    }
  }
}
</style>
