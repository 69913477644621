import "./assets/style/reset.css";

import { createApp, ref } from 'vue'
import { createStore } from 'vuex'
import App from './App.vue'

// Create a new store instance.
const store = createStore({
  state: {
    categories: [
      {
        title: 'Design',
      },
      {
        title: 'Writing',
      },
      {
        title: 'Development',
      },
      {
        title: 'Event',
      }
    ],
    checkedCategories: ['Writing', 'Design', 'Development', 'Event'],
    themes: {
      'anthropocene': ['#00ff05', '#6923f6', '#ff1607', '#1614fa'],
      'spring day': ['#b5e0d8', '#49f173', '#7f9f62', '#629f90'],
      'big tech': ['#4081ea', '#ea4335', '#fbbc05', '#26bb4e'],
      'wonderwood': ['#3E606F', '#91AAB4', '#CBDBD7', '#193441'],
      'bisexual lighting': ['#f181bb', '#a910e8', '#6900e1', '#ff1f93'],
    },
    selectedTheme: 'anthropocene',
    currentMode: 'light',
    displayPosts: 'Details',
  },
  getters: {
    colorCategories(state) {
      const theme = state.themes[state.selectedTheme];
      const themeObject = Array.from(state.categories)
      themeObject.forEach((e, index) => e.color = theme[index]);
      return themeObject;
    },
    accentColour(state) {
      const theme = state.themes[state.selectedTheme];

      const lightnessIndex = theme.map(c => getPerceivedBrightness(c));

      const darkestIndex = lightnessIndex.indexOf(Math.max(...lightnessIndex));
      const lightestIndex = lightnessIndex.indexOf(Math.min(...lightnessIndex));

      function getPerceivedBrightness(hexColor) {
        // Convert hex color code to RGB values
        var r = parseInt(hexColor.slice(1, 3), 16);
        var g = parseInt(hexColor.slice(3, 5), 16);
        var b = parseInt(hexColor.slice(5, 7), 16);

        // Calculate perceived brightness
        var brightness = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

        return brightness;
      }

      document.documentElement.style.setProperty('--accent-darkmode', theme[darkestIndex]);
      document.documentElement.style.setProperty('--accent-lightmode', theme[lightestIndex]);

      return [theme[darkestIndex], theme[lightestIndex]];
    }
  },
  mutations: {
    setNames(state, val) {
      state.checkedCategories = val;
    },
    setTheme(state, val) {
      state.selectedTheme = val;
      state.categories.forEach((c, index) => c.color = state.themes[val][index])
    },
    colourMode(state, payload) {
      state.currentMode = payload;
    },
    setDisplay(state, payload) {
      state.displayPosts = payload;
    }
  }
})

const app = createApp(App)

app.use(store)

app.config.globalProperties.breakpoints = {
  sm: 800,
  md: 1024,
  lg: 1200,
};

app.config.globalProperties.winWidth = ref(window.innerWidth);

app.config.globalProperties.winWidth = ref(window.innerWidth);
app.config.globalProperties.screenMobile = ref(
  window.innerWidth < app.config.globalProperties.breakpoints.sm
);

window.addEventListener("resize", function () {
  app.config.globalProperties.winWidth.value = window.innerWidth;
  app.config.globalProperties.screenMobile.value =
    app.config.globalProperties.winWidth.value <
    app.config.globalProperties.breakpoints.sm;
});

app.mount('#app')
