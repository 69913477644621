<template>
  <nav class="dropdown displayToggle">
    <p class="title">Display</p>
    <ul>
      <li
        v-for="(option, index) in ['Details', 'Overview']"
        :key="index"
      >
        <label
          :href="option.link"
          target="_blank"
          :for="option"
          class="list--option"
        >
          <input
            type="radio"
            :value="option"
            :id="option"
            name="display"
            v-model="displayPosts"
          >
          <p>{{ option }}</p>
          <span class="arrow">→</span>
        </label>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  methods: {
    setDarkMode({ matches }) {
      if (this.darkModeEnabled !== matches) {
        this.$store.commit('colourMode', 'dark');
      } else {
        this.$store.commit('colourMode', 'light');
      }
    }
  },
  computed: {
    displayPosts: {
      get() {
        return this.$store.state.displayPosts;
      },
      set(value) {
        this.$store.commit("setDisplay", value);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dropdown {
  display: flex;

  .title {
    margin-right: 0.5rem;
  }

  .list--option {
    text-decoration: none;
    color: var(--fore);
    margin-bottom: 0.2rem;
  }

  li label {
    list-style: none;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    input+p {
      opacity: 0.25;
      transition: 0.2s opacity ease;

      &:hover {
        opacity: 1;
      }
    }

    input:checked+p {
      opacity: 1;
    }

    .arrow {
      opacity: 0;
      font-family: 'Open Arrow';
      margin-left: 0.5rem;
      color: var(--clr-accent);
      transition: 0.2s opacity ease;
    }

    &:hover {
      .arrow {
        opacity: 1;
      }
    }

  }

}
</style>