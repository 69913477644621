<template>
  <table :class="{ list: displayPosts == 'Overview' && !screenMobile.value }">
    <TransitionGroup name="slide-in" tag="tbody" mode="out-in" :style="{ '--total': filterProjects.length }">
      <tr class="project-row" v-for="(project, index) in filterProjects" :key="project.title"
        @mouseover="hovered = project.title" @mouseleave="hovered = false" @click="visitedCounters[index] = true"
        :class="{ 'noLink': !project.link }" :style="{ '--i': index }">
        <a :href="project.link" class="row--link" target="_blank">
          <td class="row__contents" key="1" :style="{ opacity: 1 - counters[index] * 0.01 }"
            :class="{ visited: visitedCounters[index] === true }">
            <div class="category-circles">
              <div v-for="  category   in   formatCategories(project.categories)  " :key="category"
                :style="{ 'background-color': colorCategories.filter(c => c.title === category).map(x => x.color) }"
                :aria-label="colorCategories.filter(c => c.title === category).map(x => x.title)"
                :title="commaCategories(project.categories)" class="category--circle">
              </div>
            </div>
            <div class="header__main">
              <div class="main__info">
                <h2 class="projectTitle">
                  {{ project.title }}
                </h2>
                <div class="row__info">
                  <p class="info--year">{{ project.year }}</p>
                  <ul class="info--with" v-if="project.with">
                    <li class="arrow"><span>→</span></li>
                    <li v-for="  w   in   project.with  " :key="w">
                      {{ w }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="main__details">
                <h3 class="tagline">{{ project.tagline }}</h3>
                <div class="cta">
                  <h3 class="background">{{ project.cta }}</h3>
                  <h3 class="
                arrow" v-if="project.link">→</h3>
                </div>
              </div>
            </div>
          </td>
        </a>
      </tr>
    </TransitionGroup>
  </table>
  <Transition name="slide-in">
    <h2 class="disclaimer" v-if="filterProjects.length === 0">Looks like there's nothing left to hide.</h2>
  </Transition>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
// import projectsJson from '../../public/data/projects.json'
import axios from 'axios';

export default {
  components: {
  },
  data() {
    return {
      projects: [],
      hovered: false,
      counters: [],
      visitedCounters: [],
    }
  },
  methods: {
    formatCategories(cat) {
      const finalCategories = Array.from(this.categories.map(c => c.title));
      const paddedArray = finalCategories.map(c => cat.includes(c) ? c : 'blank');
      return paddedArray;
    },
    commaCategories(cate) {
      const finalCategories = Array.from(this.categories.map(c => c.title));
      const finalArray = finalCategories.filter(c => cate.includes(c))
      return finalArray.join(', ');
    },
    fetchProjects() {
      axios.get('/data/projects.json')
        .then(response => {
          console.log('Fetch'); /* DEBUG */
          this.projects = response.data;
          console.log(this.projects, 'TEST'); /* DEBUG */
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }
  },
  computed: {
    ...mapState(['categories', 'checkedCategories', 'themes', 'selectedTheme', 'displayPosts']),
    ...mapGetters(['colorCategories', 'accentColour']),
    filterProjects() {
      return this.projects.filter(p => p.categories.every(item => this.checkedCategories.includes(item)))
    },
  },
  created() {
    this.fetchProjects();
  },
  mounted() {
    const rowCount = document.querySelectorAll('.project-row').length;
    this.counters = new Array(rowCount).fill(0);
    this.visitedCounters = new Array(rowCount).fill(false);
  }
}
</script>

<style lang="scss" scoped>
/* we will explain what these classes do next! */

.disclaimer {
  font-size: 1.7rem;
  color: var(--fore);
  padding: 1.2rem;
  padding-top: 3rem;
  text-align: center;
}

.slide-in {
  &-move {
    transition: opacity .3s ease, filter 0.4s ease, transform 0.4s ease;
  }

  &-leave-active {
    transition: opacity .3s ease, transform .4s cubic-bezier(.5, 0, .7, .4), filter 0.4s ease; //cubic-bezier(.7,0,.7,1); 
    // transition-delay: calc(0.05s * (var(--total) - var(--i)));
    position: absolute;

    .cta {
      opacity: 0;
    }
  }

  &-enter-active {
    transition: opacity .3s ease, transform .4s ease, filter 0.4s ease;
    // transition-delay: calc(0.05s * var(--i));
  }

  &-enter-from,
  &-leave-to {
    opacity: 0;

    .cta {
      opacity: 0;
    }
  }

  &-enter-from {
    transform: translateY(2em);
  }

  &-leave-to {
    transform: translateY(2em);
  }

}



table {
  border-top: 1px solid var(--grey-t);
  border-collapse: collapse;

  width: 100%;

  h1,
  h2,
  h3 {
    transition: 0.4s font-size cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }

  tbody {
    // display: grid;
    // grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }

  &.list {
    --circle: var(--font-s);
    ;

    .category-circles {
      top: 0;
    }

    .project-row {
      max-width: 100%;
      width: 100%;
    }

    .row__info {
      margin-bottom: 0;
    }

    .projectTitle {
      font-size: 1rem;
      max-width: 100%;
    }

    .header__main {
      position: relative;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .main__details {
      flex-direction: row;
      align-items: center;
      width: max-content;
      justify-content: flex-end;

      .tagline {
        margin-top: 0;
        max-width: 80ch;
        display: none;
      }
    }

    .cta {
      margin-top: 0;
      transition: 0.2s opacity ease;
    }

    .row__contents {
      padding: 0.8rem;
      padding-left: 1rem;
    }
  }
}

td {
  // border-top: 1px solid rgb(233, 232, 232);

  transition: 0.4s transform ease-in-out, 0.4s background-color ease-in-out;
  height: 100%;

  &.active {
    transform: translateY(0);
  }
}

.project-row {

  background-color: var(--back);

  flex-grow: 1;
  min-width: 100px;
  max-width: 50%;

  .row--link {
    height: 100%;
  }

  .row__contents {
    border-bottom: 1px solid var(--grey-t);
    color: var(--fore);
    padding: 1.2rem 1.2rem 1.3rem 1.2rem;
    display: grid;
    height: 100%;
    width: 100%;
    grid-template-columns: max-content 1fr;
    grid-gap: 1rem;
    flex-direction: row-reverse;
    // justify-content: space-between;
    align-items: flex-start;

    transition: 0.4s padding ease, 0.4s background-color ease, 0.4s color ease;

    &.visited {
      background-color: rgba(0, 0, 0, 0.03);
    }
  }

  .header__main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
    height: 100%;

    h3 {
      font-size: var(--font-s);
    }
  }

  .main__details {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-grow: 1;

    .tagline {
      margin-top: 0.8rem;
      max-width: 50ch;
      font-size: 1rem;
      opacity: 0.7;
      line-height: 1.3;

    }
  }

  .row__details {
    padding: 0.5rem 1rem;
    height: 100%;
    background-color: none;
    // opacity: 0.2;
    display: flex;
    justify-content: space-between;
    align-items: center;

  }
}

td {
  display: block;
}

.projectTitle {
  // font-size: var(--font-l);
  // white-space: nowrap;
  // text-overflow: ellipsis;
  font-size: var(--font-l);
  max-width: 50ch;
  line-height: 1.1;
}

.cta {
  font-size: 1rem;
  display: flex;
  color: var(--back);
  padding: 0.4rem 0.7rem;
  background-color: var(--clr-accent);
  border-radius: 20px;
  margin-top: 1.5rem;
  width: max-content;

  transition: 0.4s background-color ease, 0.4s color ease;

  .noLink & {
    background-color: var(--grey-text);
  }

  .background {
    white-space: nowrap;
  }

  .arrow {
    margin-left: 0.5rem;
    font-family: 'Open Arrow';
  }

}

.category-circles {
  flex-shrink: 0;
  position: relative;
  transition: 0.2s opacity ease;
  top: 0.22em;
  overflow: hidden;

  .category--circle {
    overflow: hidden;
    display: block;
    height: var(--circle);
    width: var(--circle);
    margin-bottom: 0.12rem;
    border-radius: 100%;
    margin-right: 0.08em;
    background-color: var(--grey-t);
    transition: 0.2s background-color ease;
  }
}

.info--with {
  display: flex;

  span {
    font-family: 'Open Arrow';
    margin: 0 0.5rem;
  }
}

.row__info {
  display: flex;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-size: var(--font-s);
  ;
  opacity: 0.35;
  transition: 0.4s opacity ease;
}


@media screen and (max-width: 800px) {


  table {
    tbody {}
  }

  .project-row {
    max-width: 100%;
    width: 100%;

    .row__contents {
      padding: 1.25rem 1rem;
      ;
    }

    .main__details {
      flex-direction: column;
      align-items: flex-start;

      .tagline {
        margin-top: 0;
      }
    }
  }

  .cta {
    align-self: flex-end;
    position: relative;
    right: -0.15rem;
    margin-top: 1rem;
  }
}

@media (hover: none) {
  .project-row:active {
    // border-bottom: none;

    // border-bottom: 1px solid var(--clr-accent);

    user-select: none;

    .row__contents {
      color: var(--back);
    }

    .row__contents {
      background-color: var(--clr-accent);
      color: var(--back);
    }

    .category-circles {
      filter: saturate(0) contrast(10%) brightness(200%);

      .category--circle {
        background-color: rgba(128, 128, 128, 0.3);
      }
    }
  }

  .noLink:active {

    .row__contents {
      background-color: var(--grey-t);
      color: var(--fore);
    }

    h2 {
      color: var(--fore);
    }

    .cta {
      color: var(--fore);
      background-color: var(--grey-text);
    }

    .row__info {
      opacity: 1;
    }

    h2,
    h3 {
      opacity: 1;
    }
  }
}


@media (hover: hover) {
  .project-row:hover {
    // border-bottom: none;

    // border-bottom: 1px solid var(--clr-accent);

    user-select: none;

    .row__contents {
      color: var(--back);
    }

    &.noLink {

      .row__contents {
        background-color: var(--grey-t);
        color: var(--fore);
      }

      h2 {
        color: var(--fore);
      }

      .cta {
        color: var(--fore);
        background-color: var(--grey-text);
      }

      .row__info {
        opacity: 1;
      }

      h2,
      h3 {
        opacity: 1;
      }
    }

    .row__contents {
      background-color: var(--clr-accent);
      color: var(--back);
    }

    .category-circles {
      filter: saturate(0) contrast(10%) brightness(200%);

      .category--circle {
        background-color: rgba(128, 128, 128, 0.3);
      }
    }
  }
}

@media screen and (min-width: 800px) {

  td:nth-child(odd) {
    border-right: 1px solid var(--grey-t);
  }

  .project-row {
    .main__details {
      flex-direction: column;
      align-items: flex-start;

      .cta {
        align-self: flex-end;
      }
    }
  }
}

@media screen and (min-width: 1200px) {

  .project-row {
    max-width: 33.333%;
  }

}
</style>