<template>
  <nav class="dropdown">
    <p
      v-if="!screenMobile.value"
      class="title"
    >{{ options.title }}</p>
    <ul>
      <a
        :href="option.link"
        target="_blank"
        class="list--option"
        v-for="(option, index) in options.choices"
        :key="index"
      >
        <li>
          {{ option.choice }}
          <a class="arrow">→</a>
        </li>
      </a>
    </ul>
  </nav>
</template>

<script>
export default {
  props: ['options'],
}
</script>

<style lang="scss" scoped>
.dropdown {
  display: flex;

  .title {
    margin-right: 0.5rem;
  }

  .list--option {
    opacity: 0.25;
    text-decoration: none;
    color: var(--fore);
    margin-bottom: 0.2rem;
    transition: 0.2s opacity ease;

    &:hover {
      opacity: 1;
    }
  }

  li {
    list-style: none;
    display: flex;
    justify-content: space-between;

    .arrow {
      opacity: 0;
      font-family: 'Open Arrow';
      margin-left: 0.5rem;
      color: var(--clr-accent);
      transition: 0.2s opacity ease;
    }

    &:hover {
      .arrow {
        opacity: 1;
      }
    }

  }

}
</style>