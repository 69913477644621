<template>
  <header-component
    :style="{ '--accent-darkmode': this.accentColour[0], '--accent-lightmode': this.accentColour[1] }"></header-component>
  <project-view
    :style="{ '--accent-darkmode': this.accentColour[0], '--accent-lightmode': this.accentColour[1] }"></project-view>
  <FooterComponent :style="{ '--accent-darkmode': this.accentColour[0], '--accent-lightmode': this.accentColour[1] }">
  </FooterComponent>
</template>

<script>
import { mapGetters } from 'vuex'
import FooterComponent from './components/FooterComponent.vue'
import HeaderComponent from './components/HeaderComponent.vue'
import ProjectView from './components/ProjectView.vue'

export default {
  name: 'App',
  data() {
    return {
      darkModeEnabled: window.matchMedia('(prefers-color-scheme: dark)').matches
    }
  },
  components: {
    FooterComponent,
    HeaderComponent,
    ProjectView
  },
  mounted() {
    /* eslint-disable */
    console.log(`%c                                   /\\
                              /\\  //\\\\
                       /\\    //\\\\///\\\\\\        /\\
                      //\\\\  ///\\////\\\\\\\\  /\\  //\\\\
         /\\          /  ^ \\/^ ^/^  ^  ^ \\/^ \\/  ^ \\
        / ^\\    /\\  / ^   /  ^/ ^ ^ ^   ^\\ ^/  ^^  \\
       /^   \\  / ^\\/ ^ ^   ^ / ^  ^    ^  \\/ ^   ^  \\       *
      /  ^ ^ \\/^  ^\\ ^ ^ ^   ^  ^   ^   ____  ^   ^  \\     /|\\
     / ^ ^  ^ \\ ^  _\\___________________|  |_____^ ^  \\   /||o\\
    / ^^  ^ ^ ^\\  /______________________________\\ ^ ^ \\ /|o|||\\
   /  ^  ^^ ^ ^  /________________________________\\  ^  /|||||o|\\
  /^ ^  ^ ^^  ^    ||___|___||||||||||||___|__|||      /||o||||||\\       |
 / ^   ^   ^    ^  ||___|___||||||||||||___|__|||          | |           |
/ ^ ^ ^  ^  ^  ^   ||||||||||||||||||||||||||||||oooooooooo| |ooooooo  |
ooooooooooooooooooooooooooooooooooooooooooooooooooooooooo
`, `font-family: monospace; color: DeepSkyBlue;`); /* DEBUG */
    /* eslint-enable */
    console.log(`%cWelcome to the back-end of my page...`, `font-family: Consolas; color: DeepSkyBlue; font-size: 14px;`); /* DEBUG */
    window.matchMedia('(prefers-color-scheme: dark)').matches ? this.$store.commit('colourMode', 'dark') : this.$store.commit('colourMode', 'light');/* DEBUG */
    this.mediaQueryList = window.matchMedia('(prefers-color-scheme: dark)')
    this.$nextTick(() => {
      this.mediaQueryList.addEventListener('change', this.setDarkMode)
    })
  },
  computed: mapGetters(['accentColour']),
  beforeUnmount() {
    this.mediaQueryList.removeEventListener('change', this.setDarkMode)
  },
  created() {
    this.accentColour;
    // document.documentElement.style.setProperty('--accent-darkmode', this.accentColour[0]);
    // document.documentElement.style.setProperty('--accent-lightmode', this.accentColour[1]);
  },
  methods: {
    setDarkMode({ matches }) {
      if (this.darkModeEnabled === matches) {
        this.$store.commit('colourMode', 'dark');
      } else {
        this.$store.commit('colourMode', 'light');
      }
    }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'Schibsted Grotesk';
  src: url('@/assets/style/fonts/SchibstedGrotesk-Medium.woff2') format('woff2'),
    url('@/assets/style/fonts/SchibstedGrotesk-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Schibsted Grotesk';
  src: url('@/assets/style/fonts/SchibstedGrotesk-Regular.woff2') format('woff2'),
    url('@/assets/style/fonts/SchibstedGrotesk-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Arrow';
  src: url('@/assets/style/fonts/OpenArrow-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  box-sizing: border-box;
  /* border: 1px solid red !important; */
}

$factor-unit: 0.25;
$factor: 1 + $factor-unit;

:root {
  --font-arrow: 'Open Arrow';
  --font: 'Schibsted Grotesk', sans-serif;
  --font-l: #{$factor * 1rem};
  --font-xl: #{$factor * $factor * $factor * 1rem};
  --font-s: #{(1 - $factor-unit) * 1rem};
  --opacity: 0.08;
  --grey-t: rgba(0, 0, 0, var(--opacity));
  --grey-text: rgba(0, 0, 0, calc(var(--opacity) + 0.1));
  --circle: 0.8rem;
  --fore: rgb(22, 22, 22);
  --back: rgb(232, 241, 249);
  --clr-accent: blue;
}

html {
  font-size: 18px;
  width: 100%;
  height: 100%;
  background-color: var(--back);
}

body {
  background-color: var(--back);
  transition: 0.4s background-color ease-in-out;
  font-family: var(--font), -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  touch-action: pan-y;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
}

h1,
h2,
h3,
p,
span,
li {
  font-weight: 400;
}

header,
footer {
  color: var(--fore);
}

::selection {
  background: #FFDF00;
  /* WebKit/Blink Browsers */
  color: var(--back);
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: inherit;
  display: block;
}

.category--circle,
.circles,
.category::before {
  transition: 0.2s background-color ease-in-out, 0.2s filter ease-in-out, 0.2s opacity ease-in-out;
}

@media (prefers-color-scheme: dark) {

  :root {
    --back: rgb(22, 22, 22);
    --fore: rgb(244, 244, 244);
    --grey-t: rgba(255, 255, 255, var(--opacity));
    --grey-text: rgba(255, 255, 255, calc(var(--opacity) + 0.1));
    --clr-accent: var(--accent-darkmode);
  }
}

@media (prefers-color-scheme: light) {

  :root {
    --clr-accent: var(--accent-lightmode);
  }
}

.category--circle {
  transition: 0.2s width ease-in-out, 0.2s height ease-in-out;
}

@media screen and (max-width: 350px) {
  html {
    font-size: 14px;
  }
}

@media screen and (max-width:600px) {
  html {
    font-size: 16px;
  }
}

@media screen and (min-width: 600px) and (max-width: 800px) {
  :root {
    --circle: 0.9rem;
  }

  html {
    font-size: 18px;
  }
}

a:focus-visible,
input:focus-visible+p,
input:focus-visible+div {
  background-color: var(--clr-accent);
  transition: 0.4s border ease;
  opacity: 1 !important;
  color: var(--back) !important;
}

tr:focus-within * {
  border: none;
  color: var(--back);
}
</style>
