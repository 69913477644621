<template>
  <footer>
    <p>Thanks for stopping by!</p>
    <a href="mailto:hello@dominik.ooo">
      <p class="get-in-touch"><span>✉️</span> Get in Touch</p>
    </a>
    <p class="disclaimer">Design & Development & Maintenance<br /> {{ new Date().getFullYear() }} - Dominik Vrabič
      Dežman</p>
  </footer>
</template>
<script>
export default {
  name: 'FooterComponent',
  data() {
    return {}
  },
  methods: {},
}
</script>
<style scoped lang="scss">
footer {
  margin-top: 8rem;
}

p {
  font-size: var(--font-l);
  text-align: center;
  margin: auto;
  width: max-content;
  white-space: pre-wrap;
  width: 100%;
}

.disclaimer {
  margin-top: 6rem;
  font-size: var(--font-s);
  // margin-bottom: 2rem;
  padding-bottom: 2rem;
  opacity: 0.5;
  user-select: none;
  line-height: 1.4;
}

a {
  box-sizing: content-box;
  width: max-content;
  margin: auto;
  min-height: 4rem;

  &:focus {
    border: none;
  }
}

.get-in-touch {
  margin-top: 0.7rem;
  color: var(--clr-accent);
  padding: 0.4rem 0.2rem;
  border-radius: 0px;
  transition: 0.4s border-radius ease, 0.4s background-color ease, 0.4s padding ease, 0.4s transform ease;
  display: flex;
  align-items: center;
  border-bottom: 1.5px solid var(--clr-accent);
}

span {
  font-size: 1.8rem;
  margin-right: 0.2rem;
}

.get-in-touch:hover {
  padding: 0.6rem 1rem;
  border-radius: 60px;
  background-color: var(--clr-accent);
  color: var(--back);
  transform: translateY(-0.2rem)
}


@media screen and (min-width: 800px) {
  p {
    font-size: var(--font-l);
  }

  .get-in-touch:hover {
    padding: 0.6rem 1rem;
  }
}

@media screen and (min-width: 1200px) {
  p {
    font-size: 1.7rem;
  }
}
</style>
