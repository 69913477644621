<template>
  <nav class="dropdown">
    <p class="title">Theme</p>
    <fieldset>
      <template
        v-for="(theme, key) in themes"
        :key="theme"
      >
        <label
          :for="key"
          :title="key.replace(/(^\w|\s\w)/g, m => m.toUpperCase())"
        >
          <input
            type="radio"
            name="dropdown-theme"
            :value="key"
            :id="key"
            v-model="setTheme"
          >
          <div class="circles">
            <div
              class="category--circle"
              v-for="colour in theme"
              :key="colour"
              :style="{ backgroundColor: colour }"
            ></div>
          </div>
          <!-- <a class="arrow">→</a> -->
        </label>
      </template>
    </fieldset>
  </nav>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
    }
  },
  computed: {
    ...mapState(['themes']),
    setTheme: {
      get: function () {
        return this.$store.state.selectedTheme;
      },
      set: function (val) {
        this.$store.commit('setTheme', val);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.category--circle {
  display: inline-block;
  white-space: nowrap;
  height: var(--circle);
  width: var(--circle);
  border-radius: 100%;
  margin-right: 0.12em;
}

input:not(:checked)~.circles {
  opacity: var(--opacity);
  filter: saturate(0) contrast(10%) brightness(0);
}

.dropdown {
  display: flex;

  .title {
    margin-right: 0.5rem;
  }

  fieldset {
    flex-shrink: 0;
  }

  .circles {
    white-space: nowrap;
    position: relative;
    top: 0.1em;
    cursor: pointer;
    margin-bottom: 0.15rem;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  label {
    list-style: none;
    display: flex;
    justify-content: space-between;

    &:last-of-type .circles {
      margin-bottom: none;
    }

    .arrow {
      visibility: hidden;
      font-family: 'Open Arrow';
      margin-left: 0.5rem;
    }

    &:hover {
      .arrow {
        visibility: visible;
      }
    }

  }

}

@media (prefers-color-scheme: dark) {
  input:not(:checked)~.circles {
    filter: saturate(0) contrast(10%) brightness(2);
  }
}

@media (hover: hover) {

  input:not(:checked)~.circles {

    &:hover {
      background-color: initial;
      opacity: 1;
      filter: saturate(1);
    }
  }

}
</style>